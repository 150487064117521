import React from 'react';
import { navigate } from 'gatsby';

import { NUMBER_SELECTION_PAGE } from '../constants/links.json';

class HomePage extends React.Component {
  componentDidMount() {
    localStorage.removeItem('quatricVariables');
    navigate(NUMBER_SELECTION_PAGE.RELATIVE);
  }

  render() {
    return null;
  }
}

export default HomePage;
